import React, { useEffect, useState } from "react";

//import icons
import { PenIcon } from "../../svgicons";
//import components
import { ProductCard, Spinner } from "../../common";

//import functions
import { addtionalData } from "../../../data/data";
import {
  addProduct,
  addProductToCart,
  addZipData,
  breadCrumbChange,
  currentProductChange,
} from "../../../redux/reducers/appreducer";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addCurrentproduct,
  addToCartLocalStorage,
  addZipToLocal,
  removeCartFromLocalStorage,
  removeCurrentProductLocal,
  setIsEcommerceEnhancementV2Enabled,
} from "../../../actions/localstorage";
import { dateFormat } from "../../../actions/formatter";
import { getCharges, getSKUsBYCode } from "../../../api/api";
import { companyUrl } from "../../../data/constants";
import ReactGA from "react-ga4";
import ldclient from "../../../services/launchDarkly.service";

const ProductsComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [additional, setAdditional] = useState({});
  const [products, setProducts] = useState([]);

  const searchParams = new URLSearchParams(location.search);
  const zipcode = searchParams.get('zipcode');
  const date = searchParams.get('date');
  const referrer = searchParams.get('referrer');
  referrer && localStorage.setItem('referrer', referrer);
  const select = (product) => {
    const currentData = {
      ...product,
      ...addtionalData,
      additionalFee: additional,
    };
    const ammount = product?.pricingStructure?.price;
    addCurrentproduct(currentData);
    addZipToLocal({ zipCode: zipcode, date: date })
    dispatch(addZipData({ zipCode: zipcode, date: date }))
    dispatch(addProduct(product));
    const data = {
      ...currentData,
      ammount: ammount,
      tax: 0,
      serviceAddress: {},
      billingInformation: {},
      taxRate: 0,
    };
    addToCartLocalStorage(data);
    dispatch(addProductToCart(data));
    navigate("/additional-items");
    ReactGA.event({
      category: 'Ecommerce',
      action: 'Add to Cart',
      label: product?.product?.name ? product.product.name : undefined,
      value: product?.pricingStructure?.price ? product.pricingStructure.price : 0,
    });
  };

  useEffect(() => {
    dispatch(breadCrumbChange(0));
    const updateFlag = async () => {
      await ldclient.waitUntilReady();
      setIsEcommerceEnhancementV2Enabled(
        ldclient.variation('is_ecommerce_enhancement_v2_enabled', false)
      );
    };
    updateFlag();

    ldclient.on('ready', async function () {
      setIsEcommerceEnhancementV2Enabled(
        ldclient.variation('is_ecommerce_enhancement_v2_enabled', false),
      );
    });

    ldclient.on('change', async function () {
      await ldclient.waitUntilReady();
      setIsEcommerceEnhancementV2Enabled(
        ldclient.variation('is_ecommerce_enhancement_v2_enabled', false),
      );
    });

    ldclient.on('failed', async function (err) {
      console.log('launch darkly connection err :>> ', JSON.stringify(err));
    });
  }, []);

  const goTohome = () => {
    window.location.href = companyUrl
  };

  useEffect(() => {
    zipcode && getData()
    removeCartFromLocalStorage();
    removeCurrentProductLocal();
    dispatch(addProductToCart(null));
    dispatch(currentProductChange({}));
  }, []);

  const getData = async () => {
    setLoading(true);
    const data = await getSKUsBYCode(zipcode);
    setProducts(data);
    const response = await getCharges();
    const charges = response.filter((d) => d?.charge?.value);
    const chargesData = charges.map((v) => ({
      ...v,
      quantity: 0,
    }));
    setAdditional(chargesData);
    setLoading(false);
  };

  return (
    <>
      <div className="container">
        <div className="form-header">
          <div className="form-header-col mt-4">
            <div className="form-header-col-inner">
              <h1 className="form-header-title">
                Dumpster Rental Rates for {zipcode} on{" "}
                {dateFormat(date)}
              </h1>
              <p className="form-header-subtitle">
                Pricing includes delivery, removal and one week of time.
              </p>
            </div>
            <button onClick={goTohome} className="btn btn-edit">
              <PenIcon />
            </button>
          </div>
          <div className="form-header-col"></div>
        </div>

        <div className="product-grid">
          {loading ? (
            <div
              style={{ height: "50vh" }}
              className="w-100 align-items-center d-flex justify-content-center"
            >
              <Spinner />
            </div>
          ) : !products.length ? (
            <div className="py-4">
              <h3 className="text-center mt-4">Product Not Found</h3>
            </div>
          ) : (
            products?.map((v, i) => (
              <ProductCard
                key={i}
                select={select}
                data={{ ...v, additionalFee: additional }}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};
export default ProductsComponent;
